import React, { useMemo } from "react"

export const useHeadline = headline => {
  const headlineNode = useMemo(() => {
    const headlineArr = headline.text.split(" ")
    const lastHeadlineWord = headlineArr.pop()
    const everyHeadlineWordButLast = headlineArr.join(" ")
    return (
      <>
        {everyHeadlineWordButLast} <br /> <span>{lastHeadlineWord}</span>
      </>
    )
  }, [])
  return headlineNode
}
