import * as React from "react"
import { PageProps } from "gatsby"

import Layout from "~/components/layout"
import SEO from "~/components/seo"
import ManagementTraineeExperiences from "~/components/sections/management-trainee-projects/experiences"

const ManagementTraineeProjectsPage: React.FC<PageProps> = () => (
  <Layout>
    <SEO title="Management Trainee Projects" />
    <ManagementTraineeExperiences />
  </Layout>
)

export default ManagementTraineeProjectsPage
