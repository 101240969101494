import React, { ReactElement, useEffect, useState } from "react"
import * as styles from "./index.module.scss"
import combineClassNames from "classnames"

interface Props {
  classNames?: string
}

export default function AnimatedCurtain({ classNames }: Props): ReactElement {
  const [isAnimated, setIsAnimated] = useState(false)

  useEffect(() => {
    setIsAnimated(true)
  }, [setIsAnimated])

  return (
    <div
      className={combineClassNames(
        styles.animation,
        {
          [styles.animation__animated]: isAnimated,
        },
        classNames
      )}
    />
  )
}
