import { graphql, useStaticQuery } from "gatsby"

const useManagementTraineeProgramPage = () => {
  const data = useStaticQuery(graphql`
    query {
      prismicManagementTraineeProgramPage {
        data {
          title {
            text
          }
          vimeo_embed_link {
            url
          }
          cta_link_text
          cta_title {
            text
          }
          cta_link {
            url
          }
          bottom_link_text
          bottom_link {
            url
          }
          experiences_title {
            text
          }
          testimonials {
            testimonial {
              document {
                ... on PrismicManagementDevelopmentProgramTestimonial {
                  id
                  data {
                    name {
                      text
                    }
                    picture {
                      localFile {
                        childImageSharp {
                          gatsbyImageData(
                            quality: 100
                            layout: CONSTRAINED
                            placeholder: BLURRED
                          )
                        }
                      }
                    }
                    position {
                      text
                    }
                    text {
                      text
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  return data.prismicManagementTraineeProgramPage.data
}

export default useManagementTraineeProgramPage
