// extracted by mini-css-extract-plugin
export var animation = "index-module--animation--b0736";
export var bottomBtn = "index-module--bottomBtn--975c2";
export var cta = "index-module--cta--03d53";
export var cta__headline = "index-module--cta__headline--e71f0";
export var description = "index-module--description--dc1e8";
export var experience = "index-module--experience--c5a98";
export var headline = "index-module--headline--717af";
export var image = "index-module--image--d24fe";
export var infoInner = "index-module--infoInner--9e233";
export var infoWrapper = "index-module--infoWrapper--7a1ec";
export var items = "index-module--items--9e6ba";
export var name = "index-module--name--900ba";
export var position = "index-module--position--148f8";
export var root = "index-module--root--eecc0";
export var stripe = "index-module--stripe--ad0b1";
export var stripeCover = "index-module--stripeCover--5b00b";
export var title = "index-module--title--88a02";
export var titleWrapper = "index-module--titleWrapper--fc05c";
export var video = "index-module--video--0cee4";