import React, { ReactElement } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import combineClassNames from "classnames"

import Container from "~/components/ui/container"
import Button from "~/components/ui/button"
import AnimatedCurtain from "~/components/ui/animated-curtain"

import * as styles from "./index.module.scss"

import useManagementTraineeProgramPage from "~/hooks/cms/useManagementTraineeProgramPage"
import { useHeadline } from "~/hooks/useHeadline"

export default function ManagementTraineeExperiences(): ReactElement {
  const pageData = useManagementTraineeProgramPage()
  const headlineNode = useHeadline(pageData.title)
  return (
    <section className={styles.root}>
      <AnimatedCurtain classNames={styles.animation} />
      <Container>
        <h1
          className={combineClassNames("headline--decorated", styles.headline)}
        >
          {headlineNode}
        </h1>
        {pageData.vimeo_embed_link.url && (
          <div className={styles.video}>
            <iframe
              width="100%"
              height="100%"
              src={pageData.vimeo_embed_link.url}
              frameBorder="0"
              allow="autoplay; encrypted-media; fullscreen"
            ></iframe>
          </div>
        )}
        <div className={styles.cta}>
          <h3
            className={combineClassNames(
              "headline--decorated",
              styles.cta__headline
            )}
          >
            {pageData.cta_title.text}
          </h3>
          {pageData.cta_link.url && pageData.cta_link_text && (
            <div>
              <Button
                to={pageData.cta_link.url}
                withCustomDimensions={false}
                isLink={true}
                variant="orange"
                uppercase={true}
              >
                {pageData.cta_link_text}
              </Button>
            </div>
          )}
        </div>
        <h2 className={styles.title}>{pageData.experiences_title.text}</h2>
      </Container>
      <div className={styles.items}>
        <Container>
          {pageData.testimonials.map(
            ({
              testimonial: {
                document: { data },
              },
            }) => {
              return (
                <div key={data.name.text} className={styles.experience}>
                  <div className={styles.infoWrapper}>
                    <div className={styles.titleWrapper}>
                      <div className={styles.stripe} />
                      <div className={styles.infoInner}>
                        <h3 className={styles.name}>{data.name.text}</h3>
                        <p className={styles.position}>{data.position.text}</p>
                      </div>
                    </div>
                    <p className={styles.description}>{data.text.text}</p>
                  </div>
                  {data.picture?.localFile?.childImageSharp
                    ?.gatsbyImageData && (
                    <div>
                      <div className={styles.image}>
                        <div className={styles.stripeCover} />
                        <GatsbyImage
                          alt={data.picture.alt || ""}
                          image={
                            data.picture.localFile.childImageSharp
                              .gatsbyImageData
                          }
                          objectFit="cover"
                          objectPosition="50% 50%"
                        />
                      </div>
                    </div>
                  )}
                </div>
              )
            }
          )}
        </Container>
      </div>
      {pageData.bottom_link_text && pageData.bottom_link.url && (
        <div className={styles.bottomBtn}>
          <Button
            to={pageData.bottom_link.url}
            withCustomDimensions={false}
            isLink={true}
            variant="orange"
            uppercase={true}
          >
            {pageData.bottom_link_text}
          </Button>
        </div>
      )}
    </section>
  )
}
